import { DemoChat } from '@components/home/DemoChat';
import { FeaturesSection } from '@components/home/FeaturesSection';
import { HeroSection } from '@components/home/HeroSection';
import { PartnerSection } from '@components/home/PartnerSection';
import { Button } from '@elements/Button';
import { Container } from '@elements/Container';
import { MetaTags } from '@elements/MetaTags';
import { Typography } from '@elements/Typography';
import useIsMobile from '@hooks/is-mobile';
import { ScrollDown } from '@icons/index';
import { useAccountContext } from '@providers/UserContext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
export const HomePage = () => {
  const { t } = useTranslation('home');
  const isMobile = useIsMobile();
  const { accountType } = useAccountContext();

  return (
    <div>
      <Container className={`flex w-full flex-col ${isMobile ? '' : 'pt-4'}`}>
        <MetaTags title={t('home')} />
        <HeroSection />
        <div className="my-5">
          <PartnerSection />
        </div>
        <div className="my-5 flex justify-center">
          <ScrollDown />
        </div>
      </Container>
      <div className="mt-4 w-[100vw] bg-light-blue py-10">
        <Typography
          variant="h2"
          className="py-5 text-center !font-bold leading-10"
          color="dark"
        >
          {t('kora_in_action.title')}
        </Typography>
        <Typography variant="body1" className="text-center">
          {t('kora_in_action.disclaimer')}
        </Typography>
        <Container>
          <div className="min-h-[500px]">
            <DemoChat />
          </div>
        </Container>
      </div>
      <Container className="py-10">
        <FeaturesSection />
      </Container>
      <div
        className="flex h-[200px] items-center justify-center"
        style={{
          background: 'linear-gradient(180deg, #F8F9FF 28.85%, #ecece3 100%)'
        }}
      >
        <Link to={`/register?type=${accountType}`}>
          <Button variant="secondary" size="sm">
            {t('try_for_free')}
          </Button>
        </Link>
      </div>
    </div>
  );
};
