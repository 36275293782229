import logo from '@assets/logo.svg';
import { MenuItem } from '@components/header/MenuItem';
import { MenuSubItem } from '@components/header/MenuSubItem';
import { NotificationBell } from '@components/header/NotificationBell';
import { Button } from '@elements/Button';
import { Employer } from '@models/Employer';
import { AccountType } from '@models/UserAccount';
import { useLogout } from '@services/auth/use-logout';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAccountContext } from 'src/providers/UserContext';

const AuthButtons = () => {
  const { t } = useTranslation('header');

  return (
    <div className="flex gap-4">
      <Link to="/register">
        <Button size="sm">{t('create_account')}</Button>
      </Link>
      <Link to="/login">
        <Button size="sm">{t('login')}</Button>
      </Link>
    </div>
  );
};

const EmployerMenu = ({ t }: { t: (key: string) => string }) => {
  return (
    <>
      <MenuItem title={t('employer.hiring_funnel')} to="/funnel" />
      <MenuItem title={t('employer.vacancies')} to="/vacancies" />
    </>
  );
};

const accountMenu = ({
  t,
  type,
  id
}: {
  t: (key: string) => string;
  type: AccountType;
  id?: string;
}) => {
  const logout = useLogout();

  if (type === AccountType.CANDIDATE) {
    return (
      <MenuItem title={<>{t('account')}</>} inverted>
        <MenuSubItem title={t('profile')} to="/profile" />
        <MenuSubItem title={t('logout')} onClick={logout} />
      </MenuItem>
    );
  }
  return (
    <MenuItem title={<>{t('account')}</>} inverted>
      <MenuSubItem title={t('settings')} to="/settings" />
      {id && (
        <MenuSubItem title={t('my_company_page')} to={`/companies/${id}`} />
      )}
      <MenuSubItem title={t('logout')} onClick={logout} />
    </MenuItem>
  );
};

const CandidateMenu = ({ t }: { t: (key: string) => string }) => {
  return (
    <>
      <MenuItem title={t('recommended_jobs')} to="/jobs" />
      <MenuItem title={t('funnel')} to="/funnel" />
    </>
  );
};

const UserMenu = () => {
  const { t } = useTranslation('header');

  const { user } = useAccountContext();
  console.log(user);

  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex gap-10">
        {user?.accountType === AccountType.CANDIDATE && <CandidateMenu t={t} />}
        {user?.accountType === AccountType.EMPLOYER && <EmployerMenu t={t} />}
      </div>
      <div className="flex items-center gap-4 pe-4">
        <NotificationBell />
        {user &&
          accountMenu({
            t,
            type: user.accountType,
            id:
              user.accountType === AccountType.EMPLOYER
                ? (user.accountInfo as Employer).id
                : undefined
          })}
      </div>
    </div>
  );
};

export const DesktopHeader = () => {
  const { isLoggedIn } = useAccountContext();

  return (
    <div className="fixed z-[99] flex w-full flex-[0_1_auto] justify-between rounded-[0px_0px_20px_20px] !bg-[#F5F5F0] p-[16px_30px] shadow-[0px_25px_30px_0px_rgba(0,0,0,0.04)]">
      <div
        className={`flex items-center gap-2 ${
          isLoggedIn ? 'w-full' : 'justify-between'
        }`}
      >
        <Link to="/">
          <img src={logo} className="me-10 h-[40px]" alt="Logo" />
        </Link>
        {isLoggedIn && <UserMenu />}
      </div>

      {!isLoggedIn && <AuthButtons />}
    </div>
  );
};
