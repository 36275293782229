import CandidateHeroVideo from '@assets/videos/candidate-hero.mp4';
import EmployerHeroVideo from '@assets/videos/employer-hero.mp4';
import { GoogleLoginButton } from '@components/auth/GoogleLoginButton';
import { Typography } from '@elements/Typography';
import VideoPlayer from '@elements/VideoPlayer';
import { AccountType } from '@models/UserAccount';
import { useAccountContext } from '@providers/UserContext';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
export const HeroSection = () => {
  const { t: tRegister } = useTranslation('register');
  const { t: tCommon } = useTranslation('common');

  const { t } = useTranslation('home', {
    keyPrefix: 'hero'
  });
  const { accountType } = useAccountContext();
  return (
    <div className="mb-8 grid grid-cols-1 gap-10 md:grid-cols-[1fr_1fr]">
      <div className="flex flex-col justify-center px-3 md:px-0">
        <Typography
          variant="h1"
          color="dark"
          className="mb-5 !text-[2.5rem] uppercase leading-[4rem] md:!text-[3.5rem] md:leading-[5rem]"
        >
          <Trans components={{ span: <span className="text-secondary" /> }}>
            {t('candidate_title')}
          </Trans>
        </Typography>
        <Typography variant="body1" className="mb-5">
          {t('subtitle')}
        </Typography>
        <div className="flex flex-col items-center justify-center md:items-start">
          <GoogleLoginButton
            accountType={accountType}
            color="secondary"
            size="sm"
            className="mb-3 w-[270px]"
            label={tRegister('sign_up_with_google')}
          />
          <div className="mb-3 flex w-[270px] items-center justify-center">
            <div className="border-gray-300 w-full border-t"></div>
            <span className="text-gray-500 mx-2 px-4">{tCommon('or')}</span>
            <div className="border-gray-300 w-full border-t"></div>
          </div>
          <Link to={`/register?type=${accountType}`} className="no-underline">
            <p className="w-[270px] pt-1 text-center text-primary">
              {t('register_with_email')}
            </p>
          </Link>
        </div>
      </div>
      <div className="flex items-center justify-center">
        {accountType === AccountType.CANDIDATE ? (
          <VideoPlayer src={CandidateHeroVideo} />
        ) : (
          <VideoPlayer src={EmployerHeroVideo} />
        )}
      </div>
    </div>
  );
};
