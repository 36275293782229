import Add from './add.svg?react';
import Attachment from './attachment.svg?react';
import BackArrow from './back-arrow.svg?react';
import Bell from './bell.svg?react';
import Chalkboard from './chalkboard.svg?react';
import Chat from './chat.svg?react';
import CheckCircle from './check-circle.svg?react';
import Check from './check.svg?react';
import Chevron from './chevron.svg?react';
import ErrorCircle from './error-circle.svg?react';
import Euro from './euro.svg?react';
import Glasses from './glasses.svg?react';
import Info from './info.svg?react';
import Link from './link.svg?react';
import Location from './location.svg?react';
import Mail from './mail.svg?react';
import Money from './money.svg?react';
import Pdf from './pdf.svg?react';
import Pencil from './pencil.svg?react';
import Search from './search.svg?react';
import SendArrow from './send-arrow.svg?react';
import Tools from './tools.svg?react';
import Trash from './trash.svg?react';
import User from './user.svg?react';
import Warning from './warning.svg?react';
const ChevronUp = ({ ...props }) => (
  <Chevron style={{ transform: 'rotate(180deg)' }} {...props} />
);

const ChevronLeft = ({ ...props }) => (
  <Chevron style={{ transform: 'rotate(90deg)' }} {...props} />
);

const ChevronRight = ({ ...props }) => (
  <Chevron style={{ transform: 'rotate(-90deg)' }} {...props} />
);

const ScrollDown = ({ className = '', ...props }) => (
  <div
    className={`relative h-[70px] w-[40px] rounded-[60px] border-[3px] border-solid border-[#333] ${className}`}
    {...props}
  >
    <div className="absolute left-1/2 top-[10px] h-[12px] w-[12px] -translate-x-1/2 animate-[wheel_2s_infinite] rounded-full bg-[#333] opacity-100" />
  </div>
);

export {
  Add as AddIcon,
  Attachment,
  BackArrow,
  Bell,
  Chalkboard as ChalkboardIcon,
  Chat as ChatIcon,
  CheckCircle,
  Check as CheckIcon,
  Chevron as ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ErrorCircle,
  Euro,
  Glasses,
  Info,
  Link,
  Location as LocationPin,
  Mail as MailIcon,
  Money as MoneyIcon,
  Pdf,
  Pencil,
  ScrollDown,
  Search,
  SendArrow,
  Tools as ToolsIcon,
  Trash,
  User,
  Warning
};
